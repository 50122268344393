// Export predefined templates below:
export const ApproveTemplate = () => import(/* webpackChunkName: "ApproveTemplate" */'./ApproveTemplate');
export const ArticleSampleTemplate = () => import(/* webpackChunkName: "ArticleSampleTemplate" */'./ArticleSampleTemplate');
export const ArticleTemplate = () => import(/* webpackChunkName: "ArticleTemplate" */'./ArticleTemplate');
export const ContactTemplate = () => import(/* webpackChunkName: "ContactTemplate" */'./ContactTemplate');
export const DummyArtikelTemplate = () => import(/* webpackChunkName: "DummyArtikelTemplate" */'./DummyArtikelTemplate');
export const ErpArtikelTemplate = () => import(/* webpackChunkName: "ErpArtikelTemplate" */'./ErpArtikelTemplate');
export const ExternalDocTemplate = () => import(/* webpackChunkName: "ExternalDocTemplate" */'./ExternalDocTemplate');
export const FreierTextTemplate = () => import(/* webpackChunkName: "FreierTextTemplate" */'./FreierTextTemplate');
export const GroupAmountTemplate = () => import(/* webpackChunkName: "GroupAmountTemplate" */'./GroupAmountTemplate');
export const GroupTemplate = () => import(/* webpackChunkName: "GroupTemplate" */'./GroupTemplate');
export const GroupTextTemplate = () => import(/* webpackChunkName: "GroupTextTemplate" */'./GroupTextTemplate');
export const OfferTemplate = () => import(/* webpackChunkName: "OfferTemplate" */'./OfferTemplate');
export const PositionTextModuleTemplate = () => import(/* webpackChunkName: "PositionTextModuleTemplate" */'./PositionTextModuleTemplate');
export const SummaryTemplate = () => import(/* webpackChunkName: "SummaryTemplate" */'./SummaryTemplate');
export const TextmoduleTemplate = () => import(/* webpackChunkName: "TextmoduleTemplate" */'./TextmoduleTemplate');
